<template>
    <div>
        <section class="our-students">
            <div class="d-flex justify-content-between align-items-end">
                <div class="common-header">
                    <div class="common-title">{{ $t('city.ourStudents') }}</div>
                    <div class="common-note" v-html="ourStudentsNote"></div>
                </div>
                <div class="head-right mt-auto" style="font-family: Trade Gothic LT Std Condensed,sans-serif">
                    {{ bgOurStudentsNote }}
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <div class="left-panel"></div>
                <div class="right-panel" style="padding-right: -50px">
                    <div class="carousel-container">
                        <swiper :options="swiperOptionVideo" ref="citySwiper" @slideChange="onSlideChange">
                            <swiper-slide v-for="(item, ind) in ourStudents" :key="ind">
                                <div class="video" @click="handelDialog(item)">
                                    <img class="img cursor" :src="item.url" alt=""/>
                                    <div class="video-play-icon cursor">▶</div>
                                </div>
                            </swiper-slide>
                        </swiper>
                        <div class="scroll-btn">
                            <div class="btn-offset cursor icon iconfont" @click="prevSwiper()"> &#xe64c;</div>
                            <div class="btn-offset cursor icon iconfont" @click="nextSwiper()"> &#xe64d;</div>
                        </div>
                    </div>
                    <el-dialog width="65%"
                               class="center-dialog"
                               :close="dialogVisibleVideoClose"
                               :visible.sync="dialogVisibleVideo">
                        <div class="dialog-header">
                            <img class="icon-img" :src="require('assets/images/icon/logo.jpg')" alt="">
                            <div class="title">{{ videoObj.title }}</div>
                            <div class="ms-auto cursor" @click="dialogVisibleVideoClose">✕</div>
                        </div>
                        <video width="100%" controls :src="videoObj.url"></video>
                        <div slot="footer" class="dialog-footer"></div>
                    </el-dialog>
                    <div class="footer-panel">
                        <div class="footer-swiper">
                            <div class="line" :class="ind === activeSlideIndex ? 'active-line' : ''"
                                 v-for="(item, ind) of ourStudents" :key="ind"></div>
                        </div>
                        <div class="footer-note">
                            {{ ourStudents[activeSlideIndex].note }}
                        </div>
                        <div class="footer-title">
                            {{ ourStudents[activeSlideIndex].title }}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

export default {
    name: 'our-students',
    data() {
        return {
            videoUrl: '',
            videoObj:{
                title: '',
                url: ''
            },
            dialogVisibleVideo: false,
            activeSlideIndex: 0,
            swiperOptionVideo: {
                pagination: false,
                autoplay: false,
                loop: true,
                slidesPerView: 2,
                spaceBetween: 10,
            },
        }
    },
    props: {
        bgOurStudentsNote: {
            type: String,
            default: null
        },
        ourStudentsNote: {
            type: String,
            default: null
        }
    },
    created() {
    },
    computed: {
        ourStudents() {
            let isCn = this.$i18n.locale === 'cn'
            return [
                {
                    title: 'Eloise',
                    videoTitle: 'Vita Student - Eloise, UK',
                    note: this.$t('common.eloiseNote'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-5-1.png',
                    mp4: 'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-5-1.mp4'
                },
                {
                    title: 'Muhammed',
                    videoTitle: 'Vita Student - Muhammad, UK',
                    note: this.$t('common.muhammedNote'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-5-2.png',
                    mp4: 'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-5-2.mp4'
                },
                {
                    title: 'Prem',
                    videoTitle: 'Vita Student Testimonial - Prem Location',
                    note: this.$t('common.premNote'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-5-3.png',
                    mp4: 'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-5-3.mp4'
                },
                {
                    title: 'Lourdes',
                    videoTitle: 'Vita Student - Lourdes, Barcelona',
                    note: this.$t('common.lourdesNote'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-5-4.png',
                    mp4: 'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-5-4.mp4'
                }
            ]
        },
    },
    mounted() {
    },
    methods: {
        handelDialog(item) {
            this.videoObj ={
                title: item.videoTitle,
                url: item.mp4
            }
            this.dialogVisibleVideo = true
        },
        dialogVisibleVideoClose() {
            this.videoObj ={
                title: '',
                url: ''
            }
            this.dialogVisibleVideo = false
        },
        onSlideChange(e) {
            this.activeSlideIndex = this.$refs.citySwiper.swiper.realIndex;
        },
        prevSwiper() {
            this.$refs.citySwiper.swiper.slidePrev();
        },
        nextSwiper() {
            this.$refs.citySwiper.swiper.slideNext();
        },
    },
}
</script>

<style lang="less" scoped>
.center-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
}

/deep/.el-dialog {
    background: #000 !important;
    color: #fff !important;
    margin: auto !important;
}

/deep/.el-dialog__header, /deep/ .el-dialog__footer {
    height: 0 !important;
    display: none !important;
}

.dialog-header {
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: #fff;

    .icon-img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

    .title {
        margin-left: 10px;
        font-size: 16px;
        line-height: 50px;
    }
}

/deep/.el-dialog__body {
    padding: 0 !important;
}

.our-students {

    .common-header {
        flex: 0 0 300px;

        .common-note {
            width: 240px;
            padding-bottom: 20px;
        }
    }

    .head-right {
        flex: 0 0 calc(100% - 300px);
        font-size: 6vw;
        color: #171717;
        text-transform: uppercase; //转大写
        text-align: right;
        padding-right: 50px;
        line-height: 0.7;
    }

    .left-panel {
        flex: 0 0 300px;
    }

    .right-panel {
        width: 75%;
        flex: 0 0 75%;
        padding-left: 10px;

        .carousel-container {
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;

            /deep/ .swiper-slide {
                position: relative;

                .img {
                    width: 100% !important;
                    object-fit: cover;
                    aspect-ratio: 528 / 356;
                }

                .video {
                    position: relative;

                    .video-play-icon {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        font-size: 24px;
                        text-align: center;
                        line-height: 40px;
                        width: 40px;
                        color: #fff;
                        background-color: #e91a4c;
                    }
                }



                .video:hover {

                    .video-play-icon {
                        border: 1px #e91a4c solid;
                        background-color: #000000;
                    }
                }
            }

        }

        .footer-panel {

            .footer-swiper {
                margin-top: 30px;
                display: flex;
                border-bottom: 1px #5f5f5f solid;

                .line {
                    width: 50%;
                }

                .active-line {
                    border-bottom: 2px #ccc solid;
                }
            }

            .footer-note {
                margin-top: 20px;
                width: 300px;
                font-weight: 700;
            }

            .footer-title {
                margin-top: 20px;
                font-weight: 500;
            }
        }
    }
}
</style>