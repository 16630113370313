<template>
    <div class="container-fluid p-0">
        <section class="feature-title">
            <div
                style="background-image: url(https://static.vitaalumnicn.com/static/vsweb/life-at-vita-bg.png);"
                class="feature-title-background"></div>
        </section>
        <section class="vita-search">
            <div class="mt-0 pt-0 container p-l-50 p-r-50">
                <h1 class="title-line mb-0">推荐您的朋友</h1>
                <div class="">选择您的邻居并获得现金奖励</div>
                <div class="link cursor" @click="$goto('/find-your-room')">
                    <div class="view-all">现在推荐</div>
                    <div class="go-btn"><span class="icon iconfont">&#xe60d;</span></div>
                </div>
            </div>
        </section>
        <section class="banner-img">
            
        </section>
        <section class="why-refer">
            <div class="common-header" style="margin-bottom: 100px;">
                <div class="common-title">
                    为什么要推荐朋友？
                </div>
                <div class="common-note">
                    介绍您的朋友来体验维塔学舍，如果他们预订，<br />
                    当他们入住时你们每人都将获得100英镑。适用<a class="text-theme" href="https://www.vitastudent.com/zh/refer" target="_blank" rel="noopener">条款和条件</a>。
                </div>
            </div>
            <div class="container-fluid p-0 m-0">
                <div class="imgs row">
                    <div class="col-3">
                        <div class="panel">
                            <div class="item">
                                <img class="img"
                                     src="https://static.vitaalumnicn.com/static/vsweb/life-at-vita-4-1.png"
                                     alt="">
                            </div>
                            <div class="footer-panel">
                                <div class="footer-title font-size-26">
                                    早晨锻炼和深夜学习将变得轻松
                                </div>
                                <div class="footer-content font-size-16">
                                    让您的朋友住在隔壁，给自己额外的动力。从您走出房间的那一刻起，你们就将一起征服世界。
                                </div>
                                <div class="icon-btn icon iconfont">&#xe60d;</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="panel">
                            <div class="item">
                                <img class="img"
                                     src="https://static.vitaalumnicn.com/static/vsweb/life-at-vita-4-2.png"
                                     alt="">
                            </div>
                            <div class="footer-panel">
                                <div class="footer-title font-size-26">
                                    早享权益
                                </div>
                                <div class="footer-content font-size-16">
                                    当您的朋友预订后，他们甚至可以在入住前开始使用维塔学舍的公共空间。他们只需当天签到，就可以像在家一样。
                                </div>
                                <div class="icon-btn icon iconfont">&#xe60d;</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="panel">
                            <div class="item">
                                <img class="img"
                                     src="https://static.vitaalumnicn.com/static/vsweb/life-at-vita-4-3.png"
                                     alt="">
                            </div>
                            <div class="footer-panel">
                                <div class="footer-title font-size-26">
                                    轻松赚钱
                                </div>
                                <div class="footer-content font-size-16">
                                    选择维塔学舍是一个容易的决定。那为什么不在这个过程中轻松赚些钱呢？每推荐一位朋友，当他们入住时，你们两人都将获得100英镑。
                                </div>
                                <div class="icon-btn icon iconfont">&#xe60d;</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="panel">
                            <div class="item">
                                <img class="img"
                                     src="https://static.vitaalumnicn.com/static/vsweb/life-at-vita-4-4.png"
                                     alt="">
                            </div>
                            <div class="footer-panel">
                                <div class="footer-title font-size-26">
                                    无限潜力
                                </div>
                                <div class="footer-content font-size-16">
                                    您可以推荐的朋友数量没有限制，这意味着您可以赚取的奖励也没有限制。
                                </div>
                                <div class="icon-btn icon iconfont">&#xe60d;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="how-it-works">
            <div class="common-header" style="margin-bottom: 40px;">
                <div class="common-title-black">
                    如何操作
                </div>
            </div>
            <div class="steps">
                <div class="header" style="font-size: 80px; margin-bottom: 30px;">
                    简单步骤
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="panel">
                            <div class="item">
                                <img class="img" src="https://vita-imgs.oss-cn-shanghai.aliyuncs.com/static/vsweb/Desktop_step-1.jpeg" alt="">
                            </div>
                            <div class="footer-panel">
                                确保你的朋友愿意被我们联系
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="panel">
                            <div class="item">
                                <img class="img" src="https://vita-imgs.oss-cn-shanghai.aliyuncs.com/static/vsweb/Desktop_step-2.webp" alt="">
                            </div>
                            <div class="footer-panel">
                                填写一个简短的表格，告诉我们您的详细信息
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="panel">
                            <div class="item">
                                <img class="img" src="https://vita-imgs.oss-cn-shanghai.aliyuncs.com/static/vsweb/Desktop_step-3.webp" alt="">
                            </div>
                            <div class="footer-panel">
                                我们的团队将与您联系，帮助您与您的朋友完成预订
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="panel">
                            <div class="item">
                                <img class="img" src="https://vita-imgs.oss-cn-shanghai.aliyuncs.com/static/vsweb/Desktop_step-4.webp" alt="">
                            </div>
                            <div class="footer-panel">
                                当他们入住时，你们每人将获得100英镑
                            </div>
                        </div>
                    </div>
                </div>
                <div class="link cursor" style="margin-left: 298px; margin-bottom: 50px;" @click="$goto('/find-your-room')">
                    <div class="view-all" style="background-color: #000; border-color: #000;">现在推荐</div>
                    <div class="go-btn" style="background-color: #fff;"><span class="icon iconfont" style="color: #000;">&#xe60d;</span></div>
                </div>
            </div>
        </section>
        <our-students class="section-black" :ourStudentsNote="ourStudentsNote"></our-students>
        <Faqs slug="refer" class="section-black"></Faqs>
    </div>
</template>

<script>

import ourStudents from '@/components/our-students'
import Faqs from '@/components/faqs'
import {getCitiesAPI} from '@/apis/common'


export default {
    name: "life-at-vita",
    components: {
        ourStudents,
        Faqs
    },
    data() {
        return {
            cities: [],
            swiperOption: {
                pagination: false,
                autoplay: false,
                loop: true
            },
        }
    },
    computed: {
        ourStudentsNote: function () {
            return this.$t('lifeAtVita.ourStudentsNote')
        },
        carousels() {
            return [
                {
                    id: 1,
                    title: this.$t('lifeAtVita.bestFacilities'),
                    note: this.$t('lifeAtVita.bestFacilitiesNote'),
                    urls: [
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-1.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-2.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-3.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-4.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-5.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-6.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-7.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-8.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-9.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-10.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-11.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-1-12.png'
                    ]
                },
                {
                    id: 2,
                    title: this.$t('lifeAtVita.service'),
                    note: this.$t('lifeAtVita.serviceNote'),
                    urls: [
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-2-1.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-2-2.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-2-3.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-2-4.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-2-5.png'
                    ]
                },
                {
                    id: 3,
                    title: this.$t('lifeAtVita.worldFamousEvents'),
                    note: this.$t('lifeAtVita.worldFamousEventsNote'),
                    urls: [
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-3-1.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-3-2.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-3-3.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-3-4.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-3-5.png'
                    ]
                },
                {
                    id: 4,
                    title: this.$t('lifeAtVita.ultimateLocations'),
                    note: this.$t('lifeAtVita.ultimateLocationsNote'),
                    urls: [
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-4-1.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-4-2.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-4-3.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-4-4.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-4-5.png'
                    ]
                },
                {
                    id: 5,
                    title: this.$t('lifeAtVita.careerOpportunities'),
                    note: this.$t('lifeAtVita.careerOpportunitiesNote'),
                    urls: [
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-5-1.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-5-2.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-5-3.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-5-4.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-5-5.png'
                    ]
                },
                {
                    id: 6,
                    title: this.$t('lifeAtVita.freeHousekeeping'),
                    note: this.$t('lifeAtVita.freeHousekeepingNote'),
                    urls: [
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-6-1.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-6-2.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-6-3.png',
                        'https://static.vitaalumnicn.com/static/vsweb/life-at-vita-2-6-4.png'
                    ]
                }
            ]
        },
        lifeCityList: function (){
            let isCn = this.$i18n.locale === 'cn'
            let arr = ['barcelona','leeds','york','coventry','nottingham','birmingham','liverpool','edinburgh','manchester','sheffield']
            const list = []
            this.cities.forEach(rest => {
                list.push({
                    id: rest.id,
                    img: rest.thumbnailUrl,
                    slug: rest.slug,
                    name: isCn ? rest.name : rest.enName,
                })
            })
            const cities = []
            arr.forEach(rest => {
                let city = list.find(item => {
                    return item.slug === rest
                })
                if (city) {
                    cities.push(city)
                }
            })
            return cities
        },
    },
    created() {
        this.getCities()
    },
    methods: {
        async getCities() {
            if (this.$store.state.cities.length > 0) {
                this.cities = this.$store.state.cities;
            } else {
                await getCitiesAPI({showApartments: 1}).then(res => {
                    if (res.status === 200) {
                        this.cities = res.data.data
                        this.$store.commit('setCities', this.cities)
                    }
                })
            }
        },
        prevSlide(index) {
            this.$refs.lifeAtVitaSwiper[index].swiper.slidePrev();
        },
        nextSlide(index) {
            this.$refs.lifeAtVitaSwiper[index].swiper.slideNext();
        },
        handleOffset(offset, type = null) {
            let scrollContainer = this.$refs['scrollContainer' + type];
            if (offset === 'left') {
                scrollContainer.scrollTo({
                    left: scrollContainer.scrollLeft - 300,
                    behavior: 'smooth' // 添加平滑滚动效果
                });
                if (type === '2') {

                }
            } else {
                scrollContainer.scrollTo({
                    left: scrollContainer.scrollLeft + 300,
                    behavior: 'smooth' // 添加平滑滚动效果
                });
                if (type === '2') {

                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import "./refer.less";

.carousel-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

</style>
